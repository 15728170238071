import React from "react";
import { HeadFC, navigate } from "gatsby";
import { getMobileOperatingSystem } from "../lib/shared";
import Layout from "../components/Layout";
import Headers from '../components/Headers'

import QrAppstore from '../images/qr/ephoria-appstore.png';
import QrPlaystore from '../images/qr/ephoria-playstore.png';

import BadgeGoogleDe from '../images/badges/google-play-badge-de.png';
import BadgeAppstoreDe from '../images/badges/appstore-de.png';

import icon3d from '../images/icon3d.png';
import showcaseImage from '../images/screens/today-screen.png';

const linkGoogle = 'https://play.google.com/store/apps/details?id=ch.ephoria';
const linkApple = 'https://apps.apple.com/ch/app/ephoria/id6499039092';

export default () => {
  const [text, setText] = React.useState('Scanne den QR Code und hol dir ephoria jetzt auf dein Smartphone.' as string)

  React.useEffect(() => {
    const platform = getMobileOperatingSystem()

    if (platform === 'iOS') {
      setText('Du wirst zum App Store weitergeleitet.')
      navigate(linkApple, {replace: true})
    }
    else if (platform === 'Android') {
      setText('Du wirst zum Play Store weitergeleitet.')
      navigate(linkGoogle, {replace: true})
    }
  }, [])

    const title = 'Download'

    return (
      <Layout lang={'de'}>

        <div className="download">
            <div>
                <img src={icon3d} alt="ephoria app icon" className="appicon"/>
            </div>
            <div>
                <h1>{title}</h1>
                <p>{text}</p>
            </div>
        </div>



        <div className="download">

            <img src={showcaseImage} alt="ephoria screenshots" style={{maxWidth: 600, marginBottom: 30}}/>

            <div>
                <p><b>iPhone</b></p>
                <a href={linkApple}>
                    <img className="qr" src={QrAppstore} alt="QR Code for App Store" />
                </a>
                <a href={linkApple}>
                    <img className="badge" src={BadgeAppstoreDe} alt="App Store" />
                </a>
            </div>

            <div>
                <p><b>Android</b></p>
                <a href={linkGoogle}>
                    <img className="qr" src={QrPlaystore} alt="QR Code for Playstore" />
                </a>

                <a href={linkGoogle}>
                    <img className="badge" src={BadgeGoogleDe} alt="Playstore" />
                </a>
            </div>


        </div>

      </Layout>
    )
}


// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    let lang = 'de'
    let title = "Download"
    let description = "Hol dir ephoria jetzt auf dein Smartphone."

    return <Headers
      lang={lang}
      title={title}
      description={description}
      location={location}
      />
}
