export const getMobileOperatingSystem = (): 'Windows Phone' | 'Android' | 'iOS' | null => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return null;
}

export const getRedirectLanguage = () => {
  try {
    if (typeof navigator === `undefined`) {
    return "en"
  }

    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en"


    switch (lang) {
      case "de":
        return "de"
      default:
        return "en"
    }
  }
  catch (e) {
    return "en"
  }
}
